import angular from 'angular';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Preview as AddressPreview } from '@admin/components/address/preview';
import { Selector as SelfStorageAddressSelector } from '@admin/components/self_storage/addresses/selector';
import { BanInfo as AccountBanInfo } from '@admin/components/account/ban_info';
import { BanUpdate as AccountBanUpdate } from '@admin/components/account/ban_update';
import { DeleteButton as AccountDeleteButton } from '@admin/components/account/delete_button';
import { NewOrderButtons } from '@admin/components/account/new_order_buttons';
import { AccountsTable } from '@admin/components/accounts/table';
import { StorageLadderModal as AccountStorageLadderModal } from '@admin/components/account/storage_ladder_modal';
import { TermCommitments as AccountTermCommitments } from '@admin/components/account/term_commitments';
import { Addresses as AccountAddresses } from '@admin/components/accounts/addresses';
import { Edit as AccountAddressEdit } from '@admin/components/account/address/edit';
import { New as AccountAddressNew } from '@admin/components/account/address/new';
import { Show as AccountAddressShow } from '@admin/components/account/address/show';
import { Attachments as AccountAttachments } from '@admin/components/accounts/attachments';
import { Settings as AccountSettings } from '@admin/components/accounts/settings';
import { AuctionUploadList } from '@admin/components/auctions/uploads/list';
import { AuctionUploadForm } from '@admin/components/auctions/uploads/form';
import { GlobalUnavailabilityForm } from '@admin/components/availability/global_unavailability/form';
import { List as GlobalUnavailabilityList } from '@admin/components/availability/global_unavailability/list';
import { JobAvailabilityForm } from '@admin/components/availability/job_availability/form';
import { List as JobAvailabilityList } from '@admin/components/availability/job_availability/list';
import { AvailabilityLaborAdjustmentForm } from '@admin/components/availability/availability_labor_adjustment/form';
import { List as AvailabilityLaborAdjustmentList } from '@admin/components/availability/availability_labor_adjustment/list';
import { List as AvailabilityRescheduleOffersList } from '@admin/components/availability/reschedule_offers/list';
import { RescheduleOfferForm as AvailabilityRescheduleOffersForm } from '@admin/components/availability/reschedule_offers/form';
import { WarehouseCapacityForm } from '@admin/components/availability/warehouse_capacity/form';
import { List as WarehouseCapacityList } from '@admin/components/availability/warehouse_capacity/list';
import { AvailabilityWidget } from '@admin/components/availability_widget/availability_widget';
import { Map as CheckinsMap } from '@admin/components/checkins/map';
import { CreateDepot } from '@admin/components/depot/create_depot';
import { DepotsList } from '@admin/components/depot/depot_list';
import { EditDepot } from '@admin/components/depot/edit_depot';
import { DiscountSelector } from '@admin/components/discount_selector/discount_selector';
import { AuditOrderEventImagesPanel } from '@admin/components/dispatch/order_audit_images/audit_order_event_images_panel';
import { VehicleInformationPanel } from '@admin/components/dispatch/vehicle_information/vehicle_information_panel';
import { Barcode as ItemBarcode } from '@admin/components/items/barcode';
import { ItemDetails } from '@admin/components/items/item_details';
import { DuplicateItemLinking } from '@admin/components/items/duplicate_item_linking';
import { RangeSlider } from '@admin/components/items/range_slider';
import { LocationImagesPanel } from '@admin/components/location_images/location_images_panel';
import { LogisticsDetailedLoad } from '@admin/components/logistics/loads/detailed';
import { LogisticsEditLoad } from '@admin/components/logistics/loads/edit';
import { LogisticsNewLoad } from '@admin/components/logistics/loads/new';
import { LogisticsLoads } from '@admin/components/logistics/loads/list';
import { LogisticsPalletInfoPanels } from '@admin/components/logistics/pallets/logistics_pallet_info_panels';
import { ParcelCarrierAccountsPanel } from '@admin/components/logistics/parcel_carrier_accounts/panel';
import { LogisticsPurchaseOrders } from '@admin/components/logistics/purchase_orders/list';
import { LogisticsPurchaseOrdersPanel } from '@admin/components/logistics/purchase_orders/panel';
import { LogisticsNewPurchaseOrder } from '@admin/components/logistics/purchase_orders/new';
import { LogisticsEditPurchaseOrder } from '@admin/components/logistics/purchase_orders/edit';
import { LogisticsNewAccount } from '@admin/components/logistics/accounts/new';
import { NotesUploader } from '@admin/components/notes_uploader/notes_uploader';
import { OpsAuditEditConfiguration } from '@admin/components/ops_audit/configurations/edit';
import { OpsAuditResultsPanel } from '@admin/components/ops_audit/audit_results/audit_results_panel';
import { TestOrders } from '@admin/components/orders/test_orders';
import { PalletImagesPanel } from '@admin/components/pallet_images/pallet_images_panel';
import { PalletRequestButton } from '@admin/components/pallet_requests/pallet_request_button';
import { PalletRequestsList } from '@admin/components/pallet_requests/pallet_requests_list';
import { CreatePalletType } from '@admin/components/pallet_types/create_pallet_type';
import { EditPalletType } from '@admin/components/pallet_types/edit_pallet_type';
import { PalletTypes } from '@admin/components/pallet_types/pallet_types';
import { QaQrCodeLink } from '@admin/components/qa_automation/qa_qr_code_link';
import { QrCodePanel } from '@admin/components/qa_automation/qr_code_panel';
import { VehicleRouteMatcher } from '@admin/components/vehicle_route_matcher/vehicle_route_matcher';
import { AuctionAccountBanner } from '@admin/components/account/auction_account_banner';
import { NonexistentParts } from '@admin/components/item_groups/nonexistent_parts';
import { ItemGroupsPanel as ItemGroupPanel } from '@admin/components/item_groups/panel';
import { TransportWeightUpsertForm } from '@admin/components/orders/moving/long_distance/transport_weight_upsert_form';
import { WalkthroughForm } from '@admin/components/orders/moving/walkthrough_form';
import { Forgive as BillingInvoiceForgive } from '@admin/components/billing/invoices/forgive';
import { MarkAsPaid as BillingInvoiceMarkAsPaid } from '@admin/components/billing/invoices/mark_as_paid';
import { ReBill as BillingInvoiceReBill } from '@admin/components/billing/invoices/re_bill';
import { Action as BillingReceiptAction } from '@admin/components/billing/receipts/action';
import { Action as BillingRefundAction } from '@admin/components/billing/refunds/action';
import { BillingSourceForm } from '@admin/components/billing/sources/form';
import { List as BillingSourcesList } from '@admin/components/billing/sources/list';
import { SourceIcon } from '@admin/components/billing/sources/source_icon';
import { AccountCancelReasonSelector } from '@admin/components/orders/account_cancel_reason_selector';
import { GenerateTestPassword } from '@admin/components/qa_automation/generate_test_password';
import { CancelButton as AccountCancelButton } from '@admin/components/account/cancel_button';
import { SuspendButton as AccountSuspendButton } from '@admin/components/account/suspend_button';
import { GiftCardWidget } from '@admin/components/gift_card_widget/gift_card_widget';
import { CreateWarehouse } from '@admin/components/warehouses/create_warehouse';
import { WarehouseDetails } from '@admin/components/warehouses/details';
import { DispatchCommunications } from '@admin/components/dispatch/communications/dispatch_communications';
import { OrderEvents } from '@admin/components/dispatch/order_events/order_events';
import { OrderReschedule } from '@admin/components/orders/order_reschedule';
import { Edit as DispatchesEdit } from '@admin/components/dispatches/edit/edit';
import { FormattedServiceType } from '@admin/components/orders/formatted_service_type';
import { SimulationErrorIcon } from '@admin/components/dispatcher/simulation_error_icon';
import { CopyURL } from '@admin/components/helpers/copy_url';
import { MoverErrorIcon } from '@admin/components/dispatcher/mover_error_icon';
import { ErrorIcon } from '@admin/components/helpers/error_icon';
import { List as OrdersList } from '@admin/components/orders/list';
import { RegionFormGroup as OrderRegionFormGroup } from '@admin/components/orders/region_form_group';
import { OrdersTable } from '@admin/components/orders/table';
import { SelectWithOther } from '@admin/components/helpers/select_with_other';
import { IssuesPanel } from '@admin/components/dispatch/issues_panel';
import { Download as AudioDownload } from '@admin/components/audio/download';
import { Listen as AudioListen } from '@admin/components/audio/listen';
import { Player as AudioPlayer } from '@admin/components/audio/player';
import { Formatter as PhoneFormatter } from '@admin/components/phone/formatter';
import { AccountFormControl as ReferralsAccountFormControl } from '@admin/components/referrals/account_form_control';
import { WarehouseReturnServiceTypeSelector } from '@admin/components/orders/warehouse_return_service_type_selector';
import { AppointmentTime as DropOffAppointmentTime } from '@admin/components/drop_off/appointment_time';
import { DispatchCuftOverrideForm as EstimationCuftOverrideForm } from '@admin/components/estimation/dispatch_cuft_override/form';
import { EstimationFlowInsightPanel as EstimationFlowPanel } from '@admin/components/estimation/insight/estimation_flow_insight_panel';
import { SkuBulkUploadForm as LogisticsSkuBulkUploadForm } from '@admin/components/logistics/skus/bulk_upload/form';
import { Selector as OrderSelector } from '@admin/components/orders/selector';
import { MoversSharedFields } from '@admin/components/orders/movers_shared_fields';
import { CreateLeadButton } from '@admin/components/leads/create_leads';
import { CloseCRMButtonLink as LeadCloseCRMButtonLink } from '@admin/components/leads/close_crm_button_link';
import { Dashboard as CouponsDashboard } from '@admin/components/coupons/dashboard';
import { Edit as CouponsEdit } from '@admin/components/coupons/edit';
import { New as CouponsNew } from '@admin/components/coupons/new';
import { Planner as NightTransportPlanner } from '@admin/components/night_transport/planner/planner';
import { Currency } from '@shared/components/helpers/currency';
import { OrderTags } from '@admin/components/orders/order_tags';
import { MoverCountIcon as DispatcherMoverCountIcon } from '@admin/components/dispatcher/mover_count_icon';
import { AddressDetails as FacilityAddressDetails } from '@admin/components/facility/address_details';
import { OrderSummaryFields } from '@admin/components/orders/show/summary_fields';
import { MovingSubtypeFormGroup } from '@admin/components/orders/moving_subtype_form_group';
import { TaskOrderRouter } from '@admin/components/orders/task_orders/router';
import { AuctionsIndex } from '@admin/components/auctions/index';
import { Details as LandingLocationContentDetails } from './landing/location/content/details';
import { LandingLocationContentForm } from './landing/location/content/form';
import { Details as LandingLocationCityDetails } from './landing/location/city/details';
import { LandingLocationCityForm } from './landing/location/city/form';
import { List as LandingLocationCityList } from './landing/location/city/list';
import { Details as LandingLocationNeighborhoodDetails } from './landing/location/neighborhood/details';
import { List as LandingLocationNeighborhoodList } from './landing/location/neighborhood/list';
import { LandingLocationNeighborhoodForm } from './landing/location/neighborhood/form';
import { LandingLocationStateForm } from './landing/location/state/form';
import { List as LandingLocationStateList } from './landing/location/state/list';
import { Details as LandingLocationRouteDetails } from './landing/location/route/details';
import { List as LandingLocationRouteList } from './landing/location/route/list';
import { LandingLocationRouteForm } from './landing/location/route/form';
import { LogisticsSkusPanel } from './logistics/skus/panel';
import { LogisticsDetailedSku } from './logistics/skus/detailed';
import { LogisticsEditSku } from './logistics/skus/edit';
import { LogisticsNewSku } from './logistics/skus/new';
import { LaborSummary as AccountLaborSummary } from './account/labor_summary';
import { AccountRateAdjustments } from './account_rate_adjustments/account_rate_adjustments';
import { Tags as AccountTags } from './account_tags/tags';
import { Tags } from './helpers/tags';
import { ItemExceptionsPanel } from './item_exceptions/panel';
import { Label } from './helpers/label';
import { List as LeadsList } from './leads/list';
import { Cancellation as CancellationDisclaimer } from './orders/disclaimers/cancellation';
import { TermContracts as OrderTermContractsDisclaimer } from './orders/disclaimers/term_contracts';
import { EstimatedCUFT as OrderEstimatedCUFT } from './orders/estimated_cuft';
import { InventorySection as OrderInventorySection } from './orders/inventory_section';
import { InventorySummary as OrderInventorySummary } from './orders/inventory_summary';
import { LaborPolicy as OrderLaborPolicy } from './orders/labor_policy';
import { MovingClocksPanel } from './moving/moving_clocks/panel';
import { OrderLaborClocksPanel } from './orders/labor_clocks/panel';
import { MultiDayContext as OrderMultiDayContext } from './orders/multi_day_context';
import { PackageSetEntries } from './package_set_entry/package_set_entries';
import { OnboardingAppointmentPricing } from './appointment_pricing/onboarding_appointment_pricing';
import { CreateTicket as CreateTechSupportTicket } from './tickets/tech_support/create_ticket';
import { Panel as TimelineActivityPanel } from './timeline_activity/panel';
import { Details as UserDetails } from './users/details';
import { UserForm } from './users/form';
import { List as UsersList } from './users/list';
import { NotificationsPanel } from './notifications/notifications_panel';
import { HistoriesPanel } from './histories/panel';
import { Table as HistoriesTable } from './histories/table';
import { HowDidYouHearAboutUs } from './leads/how_did_you_hear_about_us';
import { ClaimsTable as AccountClaimsTable } from './accounts/claims_table';
import { AddCreditForm as BillingAddCreditForm } from './billing/charges/add_credit_form';
import { ChargeForm as BillingChargeForm } from './billing/charges/charge_form';
import { ClaimDashboard, ClaimDetails } from './claims';
import { GratuitiesDashboard } from './gratuities';
import { PricingUploadForm } from './pricing/uploads/form';
import { List as PricingUploadsList } from './pricing/uploads/list';
import { CustomerTicketPanel } from './shared/customer_tickets_panel';
import { Details as CustomerTicketDetails } from './customer_tickets/details';
import { Queue as CustomerTicketQueue } from './customer_tickets/queue';
import { PaymentPlansPanel as AccountPaymentPlansPanel } from './accounts/payment_plans/panel';
import { Edit as EditAccountPricing } from './accounts/pricing/edit';
import { Details as PaymentPlanDetails } from './payment_plans/details';
import { New as PaymentPlanNew } from './payment_plans/new';
import { NotesPanel } from './notes/panel';
import { TicketMessagesPanel } from './ticket_messages/panel';
import { Widget as NotesWidget } from './notes/widget';
import { Dashboard as RegionsDashboard } from './regions/dashboard';
import { Details as RegionDetails } from './regions/details';
import { Edit as RegionEdit } from './regions/edit';
import { SubscribePanel } from './subscribe/panel';
import { SubscriptionsPanel } from './subscriptions/panel';
import { Summary as SubscriptionsSummary } from './subscriptions/summary';
import { Walkthroughs } from './orders/walkthrough';
import { Rentals as SelfStorageAccountRentals } from './self_storage/account/rentals';
import { Edit as SelfStorageEditBilling } from './self_storage/billing/edit';
import { List as SelfStorageFacilitiesList } from './self_storage/facilities/list';
import { Cancel as SelfStorageRentalCancel } from './self_storage/rentals/cancel';
import { List as SelfStorageRentalsList } from './self_storage/rentals/list';
import { Form as SelfStorageRentalForm } from './self_storage/rentals/form';
import { Detail as SelfStorageReservationDetail } from './self_storage/reservations/detail';
import { Form as SelfStorageReservationForm } from './self_storage/reservations/form';
import { List as SelfStorageReservationList } from './self_storage/reservations/list';
import { New as SelfStorageReservationNew } from './self_storage/reservations/new';
import { Summary as SelfStorageReservationSummary } from './self_storage/reservations/summary';
import { Detail as SelfStorageUnitDetail } from './self_storage/units/detail';
import { Form as SelfStorageUnitForm } from './self_storage/units/form';
import { FacilityReservationSelector as SelfStorageFacilityReservationSelector } from './self_storage/shuttles/facility_reservation_selector';
import { ShuttleFacilityAddress as SelfStorageShuttleFacilityAddress } from './self_storage/shuttles/shuttle_facility_address';
import { SelfStorageUnits } from './self_storage/units';
import { Formatter as CategoryFormatter } from './categories/formatter';
import { Formatter as DimensionsFormatter } from './dimensions/formatter';
import { Labels as DimensionsLabels } from './dimensions/labels';
import { Fetcher as InventoryFetcher } from './inventory/fetcher';
import { Metadata as ItemMetadata } from './items/metadata';
import { SelectableBrick as ItemSelectableBrick } from './items/selectable_brick';
import { MaterialCategoryPreview as ItemMaterialCategoryPreview } from './items/material_category_preview';
import { PartCategoryPreview as ItemPartCategoryPreview } from './items/part_category_preview';
import { Selector as RateGroupSelector } from './rate_group/selector';
import { Map } from './helpers/map';
import { RateAdjustmentUploaderForm } from './rate_adjustment/uploader/form';
import { OpsAuditAuditReview } from './ops_audit/audit_review/audit_review';
import { OpsAuditConfigurations } from './ops_audit/configurations';
import { OpsAuditAudit } from './ops_audit/audit/audit';
import { TicketsPanel } from './tickets/panel';
import { DocumentImagesPanel } from './order_events/document_images_panel';
import { MovingOverrideLaborRateAmount } from './orders/moving/override_labor_rate/amount';
import { DamagedItemsPanel } from './damaged_items/damaged_items_panel';
import { OrderMap } from './orders/map';
import { OrderShipmentsPanel } from './orders/shipments/panel';
import { OrderSignaturesPanel } from './orders/signatures/panel';
import { OrdersMissingSignature } from './orders/signatures/orders_missing_signature';
import { ResendTodayUrlButton } from './orders/resend_today_url_button';
import { ResolveTicket as ResolveTechSupportTicket } from './tickets/tech_support/resolve_ticket';
import { AttestationIssuesTable } from './shifts/attestation/attestation_issues_table';
import { ShiftModalContainer } from './shifts/modal_container';
import { PredecessorOrderSelector } from './orders/predecessor_order_selector';
import { IngestionUploadForm } from './ingestion/uploads/form';
import { List as IngestionUploadsList } from './ingestion/uploads/list';
import { AccountReferralCode } from './account/referral_code';
import { Preview as AddressDetailPreview } from './address/detail/preview';
import { Selector as AddressSelector } from './address/selector';
import { Queue as HomeTicketQueue } from './tickets/home';
import { AuthorizationHoldsPanel } from './billing/authorization_holds/panel';
import { VehicleTypes } from './vehicle_types/vehicle_types';
import { EditVehicleType } from './vehicle_types/edit_vehicle_type';
import { CreateVehicleType } from './vehicle_types/create_vehicle_type';
import { AccessCustomerAccountButton } from './accounts/access_customer_acccount_button';
import { WaitlistRequests as OrderWaitlistRequests } from './orders/waitlist_requests';
import { OrderReturnRouteButton } from './orders/order_return_route_button';
import { OrderPickupCompleteButton } from './orders/order_pickup_complete_button';
import { ManageFollowUps } from './moving/follow_ups/manage';
import { OrderShow } from './orders/show';
import { HRSRoleDefaultsList } from './hrs/role_defaults/list';
import { HRSProfileDefaultsList } from './hrs/profile_defaults/list';
import { HRSLocationsList } from './hrs/locations/list';
import { PTORequestsList } from './pto/requests/list';
import { FeatureFlagsList } from './feature_flags/list';
import {
  WarehouseOperatingScheduleTemplateCreateForm,
  RegionOperatingScheduleTemplateCreateForm,
} from './availability/operating_schedules/template_create_form';
import { OrderLeadTimeList } from './order_lead_time/list';
import { ZendeskUpload } from './zendesk/zendesk_upload';
import { ContractorPasswordResetRequestApproveForm } from './contractor_password_reset_request/approve';
import { AttendancePolicyViolationsList } from './attendance_policy_violations/list';
import { TrainingUploadList } from './training_upload/list';
import { TrainingUploadAttestationsList } from './training_upload/attestations/list';

const app = angular.module('app');

// NOTE: needed for 'react-component' directive provided by ng-react.
// Please keep list sorted in case sensitive alphabetical order (e.g. OrderTags before OrdersList).
app.value('AccessCustomerAccountButton', AccessCustomerAccountButton);
app.value('AccountAddressEdit', AccountAddressEdit);
app.value('AccountAddresses', AccountAddresses);
app.value('AccountAddressNew', AccountAddressNew);
app.value('AccountAddressShow', AccountAddressShow);
app.value('AccountAttachments', AccountAttachments);
app.value('AccountBanInfo', AccountBanInfo);
app.value('AccountBanUpdate', AccountBanUpdate);
app.value('AccountDeleteButton', AccountDeleteButton);
app.value('AccountCancelButton', AccountCancelButton);
app.value('AccountCancelReasonSelector', AccountCancelReasonSelector);
app.value('AccountClaimsTable', AccountClaimsTable);
app.value('AccountLaborSummary', AccountLaborSummary);
app.value('AccountPaymentPlansPanel', AccountPaymentPlansPanel);
app.value('AccountRateAdjustments', AccountRateAdjustments);
app.value('AccountReferralCode', AccountReferralCode);
app.value('AccountSettings', AccountSettings);
app.value('AccountsTable', AccountsTable);
app.value('AccountStorageLadderModal', AccountStorageLadderModal);
app.value('AccountSuspendButton', AccountSuspendButton);
app.value('AccountTags', AccountTags);
app.value('AccountTermCommitments', AccountTermCommitments);
app.value('AddressDetailPreview', AddressDetailPreview);
app.value('AddressPreview', AddressPreview);
app.value('AddressSelector', AddressSelector);
app.value('AttendancePolicyViolationsList', AttendancePolicyViolationsList);
app.value('AttestationIssuesTable', AttestationIssuesTable);
app.value('AuctionAccountBanner', AuctionAccountBanner);
app.value('AuctionsIndex', AuctionsIndex);
app.value('AuctionUploadForm', AuctionUploadForm);
app.value('AuctionUploadList', AuctionUploadList);
app.value('AudioDownload', AudioDownload);
app.value('AudioListen', AudioListen);
app.value('AudioPlayer', AudioPlayer);
app.value('AuditOrderEventImagesPanel', AuditOrderEventImagesPanel);
app.value('AuthorizationHoldsPanel', AuthorizationHoldsPanel);
app.value('AvailabilityLaborAdjustmentForm', AvailabilityLaborAdjustmentForm);
app.value('AvailabilityLaborAdjustmentList', AvailabilityLaborAdjustmentList);
app.value('AvailabilityRescheduleOffersList', AvailabilityRescheduleOffersList);
app.value('AvailabilityRescheduleOffersForm', AvailabilityRescheduleOffersForm);
app.value('AvailabilityWidget', AvailabilityWidget);
app.value('BillingAddCreditForm', BillingAddCreditForm);
app.value('BillingChargeForm', BillingChargeForm);
app.value('BillingInvoiceForgive', BillingInvoiceForgive);
app.value('BillingInvoiceMarkAsPaid', BillingInvoiceMarkAsPaid);
app.value('BillingInvoiceReBill', BillingInvoiceReBill);
app.value('BillingReceiptAction', BillingReceiptAction);
app.value('BillingRefundAction', BillingRefundAction);
app.value('BillingSourceForm', BillingSourceForm);
app.value('BillingSourcesList', BillingSourcesList);
app.value('CancellationDisclaimer', CancellationDisclaimer);
app.value('CategoryFormatter', CategoryFormatter);
app.value('CheckinsMap', CheckinsMap);
app.value('ClaimDetails', ClaimDetails);
app.value('ClaimsDashboard', ClaimDashboard);
app.value('ContractorPasswordResetRequestApproveForm', ContractorPasswordResetRequestApproveForm);
app.value('CopyURL', CopyURL);
app.value('CouponsDashboard', CouponsDashboard);
app.value('CouponsEdit', CouponsEdit);
app.value('CouponsNew', CouponsNew);
app.value('CreateDepot', CreateDepot);
app.value('CreateLeadButton', CreateLeadButton);
app.value('CreatePalletType', CreatePalletType);
app.value('CreateTechSupportTicket', CreateTechSupportTicket);
app.value('CreateVehicleType', CreateVehicleType);
app.value('CreateWarehouse', CreateWarehouse);
app.value('Currency', Currency);
app.value('CustomerTicketDetails', CustomerTicketDetails);
app.value('CustomerTicketPanel', CustomerTicketPanel);
app.value('CustomerTicketQueue', CustomerTicketQueue);
app.value('DamagedItemsPanel', DamagedItemsPanel);
app.value('DepotsList', DepotsList);
app.value('DimensionsFormatter', DimensionsFormatter);
app.value('DimensionsLabels', DimensionsLabels);
app.value('DiscountSelector', DiscountSelector);
app.value('DispatchCommunications', DispatchCommunications);
app.value('DispatcherMoverCountIcon', DispatcherMoverCountIcon);
app.value('DispatchesEdit', DispatchesEdit);
app.value('DocumentImagesPanel', DocumentImagesPanel);
app.value('DropOffAppointmentTime', DropOffAppointmentTime);
app.value('DuplicateItemLinking', DuplicateItemLinking);
app.value('EditAccountPricing', EditAccountPricing);
app.value('EditDepot', EditDepot);
app.value('EditPalletType', EditPalletType);
app.value('EditVehicleTypes', EditVehicleType);
app.value('ErrorIcon', ErrorIcon);
app.value('EstimationCuftOverrideForm', EstimationCuftOverrideForm);
app.value('EstimationFlowPanel', EstimationFlowPanel);
app.value('FacilityAddressDetails', FacilityAddressDetails);
app.value('FeatureFlagsList', FeatureFlagsList);
app.value('FontAwesomeIcon', FontAwesomeIcon);
app.value('FormattedServiceType', FormattedServiceType);
app.value('GenerateTestPassword', GenerateTestPassword);
app.value('GiftCardWidget', GiftCardWidget);
app.value('GlobalUnavailabilityForm', GlobalUnavailabilityForm);
app.value('GlobalUnavailabilityList', GlobalUnavailabilityList);
app.value('GratuitiesDashboard', GratuitiesDashboard);
app.value('HistoriesPanel', HistoriesPanel);
app.value('HistoriesTable', HistoriesTable);
app.value('HomeTicketQueue', HomeTicketQueue);
app.value('HowDidYouHearAboutUs', HowDidYouHearAboutUs);
app.value('HRSLocationsList', HRSLocationsList);
app.value('HRSProfileDefaultsList', HRSProfileDefaultsList);
app.value('HRSRoleDefaultsList', HRSRoleDefaultsList);
app.value('IngestionUploadForm', IngestionUploadForm);
app.value('IngestionUploadsList', IngestionUploadsList);
app.value('InventoryFetcher', InventoryFetcher);
app.value('IssuesPanel', IssuesPanel);
app.value('ItemBarcode', ItemBarcode);
app.value('ItemDetails', ItemDetails);
app.value('ItemExceptionsPanel', ItemExceptionsPanel);
app.value('ItemGroupPanel', ItemGroupPanel);
app.value('ItemMaterialCategoryPreview', ItemMaterialCategoryPreview);
app.value('ItemMetadata', ItemMetadata);
app.value('ItemPartCategoryPreview', ItemPartCategoryPreview);
app.value('ItemSelectableBrick', ItemSelectableBrick);
app.value('JobAvailabilityForm', JobAvailabilityForm);
app.value('JobAvailabilityList', JobAvailabilityList);
app.value('Label', Label);
app.value('LandingLocationCityDetails', LandingLocationCityDetails);
app.value('LandingLocationCityForm', LandingLocationCityForm);
app.value('LandingLocationCityList', LandingLocationCityList);
app.value('LandingLocationContentDetails', LandingLocationContentDetails);
app.value('LandingLocationContentForm', LandingLocationContentForm);
app.value('LandingLocationNeighborhoodDetails', LandingLocationNeighborhoodDetails);
app.value('LandingLocationNeighborhoodForm', LandingLocationNeighborhoodForm);
app.value('LandingLocationNeighborhoodList', LandingLocationNeighborhoodList);
app.value('LandingLocationRouteDetails', LandingLocationRouteDetails);
app.value('LandingLocationRouteForm', LandingLocationRouteForm);
app.value('LandingLocationRouteList', LandingLocationRouteList);
app.value('LandingLocationStateForm', LandingLocationStateForm);
app.value('LandingLocationStateList', LandingLocationStateList);
app.value('LeadCloseCRMButtonLink', LeadCloseCRMButtonLink);
app.value('LeadsList', LeadsList);
app.value('LocationImagesPanel', LocationImagesPanel);
app.value('LogisticsDetailedLoad', LogisticsDetailedLoad);
app.value('LogisticsDetailedSku', LogisticsDetailedSku);
app.value('LogisticsEditLoad', LogisticsEditLoad);
app.value('LogisticsEditPurchaseOrder', LogisticsEditPurchaseOrder);
app.value('LogisticsEditSku', LogisticsEditSku);
app.value('LogisticsLoads', LogisticsLoads);
app.value('LogisticsNewAccount', LogisticsNewAccount);
app.value('LogisticsNewLoad', LogisticsNewLoad);
app.value('LogisticsNewPurchaseOrder', LogisticsNewPurchaseOrder);
app.value('LogisticsNewSku', LogisticsNewSku);
app.value('LogisticsPalletInfoPanels', LogisticsPalletInfoPanels);
app.value('LogisticsPurchaseOrders', LogisticsPurchaseOrders);
app.value('LogisticsPurchaseOrdersPanel', LogisticsPurchaseOrdersPanel);
app.value('LogisticsSkuBulkUploadForm', LogisticsSkuBulkUploadForm);
app.value('LogisticsSkusPanel', LogisticsSkusPanel);
app.value('ManageFollowUps', ManageFollowUps);
app.value('Map', Map);
app.value('MoverErrorIcon', MoverErrorIcon);
app.value('MoversSharedFields', MoversSharedFields);
app.value('MovingClocksPanel', MovingClocksPanel);
app.value('MovingOverrideLaborRateAmount', MovingOverrideLaborRateAmount);
app.value('MovingSubtypeFormGroup', MovingSubtypeFormGroup);
app.value('NewOrderButtons', NewOrderButtons);
app.value('NightTransportPlanner', NightTransportPlanner);
app.value('NonexistentParts', NonexistentParts);
app.value('NotesPanel', NotesPanel);
app.value('NotesUploader', NotesUploader);
app.value('NotesWidget', NotesWidget);
app.value('NotificationsPanel', NotificationsPanel);
app.value('OnboardingAppointmentPricing', OnboardingAppointmentPricing);
app.value('OpsAuditAudit', OpsAuditAudit);
app.value('OpsAuditAuditReview', OpsAuditAuditReview);
app.value('OpsAuditConfigurations', OpsAuditConfigurations);
app.value('OpsAuditEditConfiguration', OpsAuditEditConfiguration);
app.value('OpsAuditResultsPanel', OpsAuditResultsPanel);
app.value('OrderEstimatedCUFT', OrderEstimatedCUFT);
app.value('OrderEvents', OrderEvents);
app.value('OrderReschedule', OrderReschedule);
app.value('OrderInventorySection', OrderInventorySection);
app.value('OrderInventorySummary', OrderInventorySummary);
app.value('OrderLaborClocksPanel', OrderLaborClocksPanel);
app.value('OrderLaborPolicy', OrderLaborPolicy);
app.value('OrderMap', OrderMap);
app.value('OrderMultiDayContext', OrderMultiDayContext);
app.value('OrderPickupCompleteButton', OrderPickupCompleteButton);
app.value('OrderRegionFormGroup', OrderRegionFormGroup);
app.value('OrderReturnRouteButton', OrderReturnRouteButton);
app.value('OrderSelector', OrderSelector);
app.value('OrderShipmentsPanel', OrderShipmentsPanel);
app.value('OrderShow', OrderShow);
app.value('OrderSignaturesPanel', OrderSignaturesPanel);
app.value('OrderSummaryFields', OrderSummaryFields);
app.value('OrderLeadTimeList', OrderLeadTimeList);
app.value('OrdersList', OrdersList);
app.value('OrdersMissingSignature', OrdersMissingSignature);
app.value('OrdersTable', OrdersTable);
app.value('OrderTags', OrderTags);
app.value('OrderTermContractsDisclaimer', OrderTermContractsDisclaimer);
app.value('OrderWaitlistRequests', OrderWaitlistRequests);
app.value('PackageSetEntries', PackageSetEntries);
app.value('PalletImagesPanel', PalletImagesPanel);
app.value('PalletRequestButton', PalletRequestButton);
app.value('PalletRequestsList', PalletRequestsList);
app.value('PalletTypes', PalletTypes);
app.value('ParcelCarrierAccountsPanel', ParcelCarrierAccountsPanel);
app.value('PaymentPlanDetails', PaymentPlanDetails);
app.value('PaymentPlanNew', PaymentPlanNew);
app.value('PhoneFormatter', PhoneFormatter);
app.value('PredecessorOrderSelector', PredecessorOrderSelector);
app.value('PricingUploadForm', PricingUploadForm);
app.value('PricingUploadsList', PricingUploadsList);
app.value('PTORequestsList', PTORequestsList);
app.value('QaQrCodeLink', QaQrCodeLink);
app.value('QrCodePanel', QrCodePanel);
app.value('RangeSlider', RangeSlider);
app.value('RateAdjustmentUploaderForm', RateAdjustmentUploaderForm);
app.value('RateGroupSelector', RateGroupSelector);
app.value('ReferralsAccountFormControl', ReferralsAccountFormControl);
app.value('RegionDetails', RegionDetails);
app.value('RegionEdit', RegionEdit);
app.value('RegionOperatingScheduleTemplateCreateForm', RegionOperatingScheduleTemplateCreateForm);
app.value('RegionsDashboard', RegionsDashboard);
app.value('ResendTodayUrlButton', ResendTodayUrlButton);
app.value('ResolveTechSupportTicket', ResolveTechSupportTicket);
app.value('SelectWithOther', SelectWithOther);
app.value('SelfStorageAccountRentals', SelfStorageAccountRentals);
app.value('SelfStorageAddressSelector', SelfStorageAddressSelector);
app.value('SelfStorageEditBilling', SelfStorageEditBilling);
app.value('SelfStorageFacilitiesList', SelfStorageFacilitiesList);
app.value('SelfStorageFacilityReservationSelector', SelfStorageFacilityReservationSelector);
app.value('SelfStorageRentalCancel', SelfStorageRentalCancel);
app.value('SelfStorageRentalForm', SelfStorageRentalForm);
app.value('SelfStorageRentalsList', SelfStorageRentalsList);
app.value('SelfStorageReservationDetail', SelfStorageReservationDetail);
app.value('SelfStorageReservationForm', SelfStorageReservationForm);
app.value('SelfStorageReservationList', SelfStorageReservationList);
app.value('SelfStorageReservationNew', SelfStorageReservationNew);
app.value('SelfStorageReservationSummary', SelfStorageReservationSummary);
app.value('SelfStorageShuttleFacilityAddress', SelfStorageShuttleFacilityAddress);
app.value('SelfStorageUnitDetail', SelfStorageUnitDetail);
app.value('SelfStorageUnitForm', SelfStorageUnitForm);
app.value('SelfStorageUnits', SelfStorageUnits);
app.value('ShiftModalContainer', ShiftModalContainer);
app.value('SimulationErrorIcon', SimulationErrorIcon);
app.value('SourceIcon', SourceIcon);
app.value('SubscribePanel', SubscribePanel);
app.value('SubscriptionsPanel', SubscriptionsPanel);
app.value('SubscriptionsSummary', SubscriptionsSummary);
app.value('Tags', Tags);
app.value('TaskOrderRouter', TaskOrderRouter);
app.value('TestOrders', TestOrders);
app.value('TicketMessagesPanel', TicketMessagesPanel);
app.value('TicketsPanel', TicketsPanel);
app.value('TimelineActivityPanel', TimelineActivityPanel);
app.value('TrainingUploadList', TrainingUploadList);
app.value('TrainingUploadAttestationsList', TrainingUploadAttestationsList);
app.value('TransportWeightUpsertForm', TransportWeightUpsertForm);
app.value('UserDetails', UserDetails);
app.value('UserForm', UserForm);
app.value('UsersList', UsersList);
app.value('VehicleInformationPanel', VehicleInformationPanel);
app.value('VehicleRouteMatcher', VehicleRouteMatcher);
app.value('VehicleTypes', VehicleTypes);
app.value('WalkthroughForm', WalkthroughForm);
app.value('Walkthroughs', Walkthroughs);
app.value('WarehouseCapacityForm', WarehouseCapacityForm);
app.value('WarehouseCapacityList', WarehouseCapacityList);
app.value('WarehouseDetails', WarehouseDetails);
app.value('WarehouseOperatingScheduleTemplateCreateForm', WarehouseOperatingScheduleTemplateCreateForm);
app.value('WarehouseReturnServiceTypeSelector', WarehouseReturnServiceTypeSelector);
app.value('ZendeskUpload', ZendeskUpload);
