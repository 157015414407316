import { warehousesURL } from '@admin/config/routes';
import { useBuildWarehouseMutation, WarehouseInput } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import * as React from 'react';
import { useState } from 'react';
import { WarehouseForm } from './warehouse_form';

const DEFAULT_WAREHOUSE_INPUT: WarehouseInput = {
  name: '',
  abbreviation: '',
  tz: '',
  height: undefined,
  thirdPartyLogistics: false,
  inboundDockItemsPerHour: 0,
  outboundDockItemsPerHour: 0,
  active: false,
  storageTreasuresFacilityID: undefined,
  address: {
    aptsuite: '',
    street: '',
    city: '',
    state: '',
    country: 'United States',
    zip: '',
  },
  regionIDs: [],
  customerAccessHours: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day) => ({
    day: day,
    open: '',
    close: '',
  })),
};

export const CreateWarehouse: React.FC = () => {
  const [warehouse, setWarehouse] = useState<WarehouseInput>(DEFAULT_WAREHOUSE_INPUT);
  const [error, setError] = useState<string | undefined>(undefined);
  const [execute, { loading }] = useBuildWarehouseMutation({ client });

  const onSave = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) {
      return;
    }
    const variables = { input: warehouse };
    const response = await execute({ variables });
    if (response && response.data) {
      setError(response.data.buildWarehouse.error ?? undefined);
      if (response.data.buildWarehouse.error === null) {
        setWarehouse(DEFAULT_WAREHOUSE_INPUT);
        window.location.href = warehousesURL();
      }
    }
  };

  return (
    <div className="panel">
      <div className="panel-heading">
        <h3 className="panel-title">Add Warehouse</h3>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
      {
        <form
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <div className="panel-body">
            <WarehouseForm warehouse={warehouse} editAccess={true} onChange={setWarehouse} />
          </div>
          <div className="panel-footer text-right">
            <button type="button" className="btn btn-primary" disabled={loading} onClick={onSave}>
              Save
            </button>
          </div>
        </form>
      }
    </div>
  );
};
