import styled from '@emotion/styled';
import React, { useState } from 'react';
import { COLORS } from '@clutter/clean';

import { Dialog } from '@admin/components/helpers/dialog';
import { Panel } from '@admin/components/helpers/panel';
import { FileFormControl } from '@admin/components/fields/file_form_control';
import { InputFormControl } from '@shared/components/fields/input_form_control';
import { Button, Breadcrumb, FormGroup } from '@shared/components/bootstrap';
import { auctionsURL } from '@admin/config/routes';

import {
  Auction__UploadFragment as Upload,
  useAuctionUploadCreateMutation,
  useAuctionUploadQuery,
  useAuctionUploadPolicyQuery,
  Auction__Upload__State,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { usePusher } from '@admin/hooks';

const Actions = styled.div`
  display: flex;
  margin: -4px;
  justify-content: flex-end;
`;

const Action = styled.div`
  padding: 4px;
`;

const Warning = styled.span`
  color: ${COLORS.toucan};
`;

const SavePermissionDenied: React.FC = () => <div>You do not have permission to upload auctions.</div>;

const Uploaded: React.FC<{ upload: Upload }> = ({ upload }) => (
  <>
    {upload.state === Auction__Upload__State.Processed && (
      <Dialog title="Processed" description="Your upload processed!" onClose={() => (location.href = auctionsURL())} />
    )}

    {upload.state === Auction__Upload__State.Failed && (
      <Dialog
        title="Failed"
        description={upload.error ?? 'Your upload failed!'}
        onClose={() => (location.href = auctionsURL())}
      />
    )}
  </>
);

const useCombinedQueryMutation = () => {
  const [create, { loading: saving, data: created }] = useAuctionUploadCreateMutation({ client });
  const uploadID = created?.create.upload?.id;
  const { data: queried, refetch } = useAuctionUploadQuery({
    client,
    variables: { id: uploadID! },
    skip: !uploadID,
  });

  const upload = queried?.upload ?? created?.create.upload;

  return {
    create,
    refetch,
    saving,
    upload,
  };
};

export const AuctionUploadForm: React.FC = () => {
  const [file, setFile] = useState<File | undefined>();
  const [description, setDescription] = useState<string>('');
  const [csvID, setCsvID] = useState<string | undefined>();
  const { create, saving, upload, refetch } = useCombinedQueryMutation();
  const { data: policy } = useAuctionUploadPolicyQuery({ client });
  const loading = saving || upload?.state === Auction__Upload__State.Pending;

  usePusher<{ id: number }>('auction-upload', 'changed', async ({ id }) => {
    if (!upload || String(id) !== upload.id) return;
    refetch();
  });

  const onUpload = (updatedFile: File, updatedCsvID: string) => {
    setFile(updatedFile);
    setCsvID(updatedCsvID);
  };

  const onSave = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!csvID) return;
    if (!description) return;

    create({
      variables: {
        input: {
          csvID,
          description,
        },
      },
    });
  };

  return (
    <>
      {upload && <Uploaded upload={upload} />}
      <div className="page-header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href={auctionsURL()}>Auctions</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Import</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {policy?.permission?.create ? (
        <form onSubmit={onSave}>
          <Panel>
            <Panel.Header>
              <Panel.Title>Upload CSV</Panel.Title>
            </Panel.Header>
            <Panel.Body>
              <p>
                Auctions can be created in bulk by uploading a csv. Download and replace the following template:{' '}
                <a href="/files/auctions.csv" className="btn-link" download target="_blank">
                  auctions.csv
                </a>
                . All headers must be present.{' '}
                <Warning>The scheduled time must be in "YYYY-MM-DD HH:mm" format.</Warning> A failure CSV can be
                modified and uploaded directly.
              </p>
              <FormGroup>
                <InputFormControl
                  placeholder="Description"
                  type="text"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <FileFormControl accept=".csv" label={file?.name} saving={saving} onUpload={onUpload} />
              </FormGroup>
            </Panel.Body>
            <Panel.Footer>
              <Actions>
                <Action>
                  <Button type="submit" kind="primary" disabled={!csvID || !description} loading={loading}>
                    Save
                  </Button>
                </Action>
              </Actions>
            </Panel.Footer>
          </Panel>
        </form>
      ) : (
        <SavePermissionDenied />
      )}
    </>
  );
};
