import React from 'react';
import { Spacer } from '@shared/components/helpers';
import { AuctionUploadList } from './uploads/list';
import { StorageTreasuresAuctionList } from '../storage_treasures/auction_list';

export const AuctionsIndex: React.FC = () => (
  <>
    <StorageTreasuresAuctionList />
    <Spacer />
    <AuctionUploadList />
  </>
);
