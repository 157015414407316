import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import {
  warehouseCapacityListURL,
  warehouseLocationsURL,
  warehousePalletRequestsURL,
  warehousesURL,
} from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import { isEqual } from 'lodash';
import {
  CustomerAccessHoursInput,
  History__Subject,
  useModifyWarehouseMutation,
  useWarehouseQuery,
  WarehouseInput,
} from '@admin/schema';
import { Breadcrumb } from '@shared/components/bootstrap';
import { ROLES } from '@admin/config/roles';
import { UserRole } from '@admin/types';
import { Spacer } from '@shared/components/helpers';
import { WarehouseForm } from './warehouse_form';
import { OrderLeadTimeList } from '../order_lead_time/list';
import { FeatureFlagsList } from '../feature_flags/list';
import { OperatingSchedule } from '../operating_schedule';
import { HistoriesPanel } from '../histories/panel';

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LinkButtonGroup = styled.div`
  padding: 16px;
`;

const LinkButton = styled.a`
  margin: 2px;
`;

export const WarehouseDetails: React.FC<{ id: string }> = ({ id }) => {
  const [savedWarehouse, setSavedWarehouse] = useState<WarehouseInput | undefined>(undefined);
  const [warehouse, setWarehouse] = useState<WarehouseInput | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [editingOtherDetails, setEditingOtherDetails] = useState<boolean>(false);

  const { data, loading: loadingQuery } = useWarehouseQuery({ client, variables: { id } });
  const [execute, { loading: loadingMutation }] = useModifyWarehouseMutation({ client });

  const editAccess = ROLES.includes(UserRole.Admin);

  const onSave = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (loadingMutation || !warehouse) {
      return;
    }
    const response = await execute({ variables: { input: warehouse, id } });
    if (response && response.data) {
      setError(response.data?.modifyWarehouse.error ?? undefined);
      if (response.data.modifyWarehouse.error === null) {
        setEditingOtherDetails(false);
      }
    }
  };

  function sanitizeTime(string: string): string {
    if (['1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00'].includes(string)) {
      return `0${string}`;
    } else {
      return string;
    }
  }

  useEffect(() => {
    if (data && data.warehouse && warehouse === undefined) {
      const newWarehouse = {
        name: data.warehouse.name,
        abbreviation: data.warehouse.abbreviation,
        tz: data.warehouse.tz,
        active: data.warehouse.active,
        height: data.warehouse.height || undefined,
        thirdPartyLogistics: data.warehouse.thirdPartyLogistics,
        inboundDockItemsPerHour: data.warehouse.inboundDockItemsPerHour,
        outboundDockItemsPerHour: data.warehouse.outboundDockItemsPerHour,
        address: {
          aptsuite: data.warehouse.address.aptsuite || '',
          street: data.warehouse.address.street,
          city: data.warehouse.address.city,
          state: data.warehouse.address.state,
          country: data.warehouse.address.country || 'United States',
          zip: data.warehouse.address.zip,
          latitude: data.warehouse.address.latitude ?? undefined,
          longitude: data.warehouse.address.longitude ?? undefined,
        },
        regionIDs: data.warehouse.regions.map((region) => region.id),
        storageTreasuresFacilityID: data.warehouse.storageTreasuresFacilityID,
        customerAccessInstructions: data.warehouse.customerAccessInstructions,
        customerAccessHours:
          data.warehouse.customerAccessHours?.map(
            (accessHours) =>
              ({
                day: accessHours.day,
                open: sanitizeTime(accessHours.open ?? ''),
                close: sanitizeTime(accessHours.close ?? ''),
              } as CustomerAccessHoursInput),
          ) ?? [],
      };
      setSavedWarehouse(newWarehouse);
      setWarehouse(newWarehouse);
    }
  }, [data, warehouse]);

  if (!data?.warehouse) {
    return null;
  }

  return (
    <>
      <div className="page-header text-overflow">
        <Navigation>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href={warehousesURL()}>Warehouses</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{data.warehouse.name}</Breadcrumb.Item>
          </Breadcrumb>
        </Navigation>
      </div>
      <h3>Operating Schedule</h3>
      <OperatingSchedule
        operatingScheduleTemplates={data.warehouse.operatingScheduleTemplates}
        manageHref={warehouseCapacityListURL()}
      />
      <Spacer height="8px" />
      <h3>Order Lead Times</h3>
      <OrderLeadTimeList warehouseID={data.warehouse.id} editAccess={editAccess} />
      <Spacer height="8px" />
      <h3>Feature Flags</h3>
      <FeatureFlagsList resourceName={'Warehouse'} resourceID={data.warehouse.id} editAccess={editAccess} />
      <Spacer height="8px" />
      <h3>Other Details</h3>
      <div className="panel">
        {editAccess && (
          <div className="panel-heading">
            <LinkButtonGroup className="text-right">
              <LinkButton onClick={() => setEditingOtherDetails(!editingOtherDetails)} className="btn btn-primary">
                {editingOtherDetails ? 'Cancel Editing' : 'Edit Details'}
              </LinkButton>
              <LinkButton href={warehouseLocationsURL(data.warehouse.id)} className="btn btn-primary">
                View Locations
              </LinkButton>
              <LinkButton href={warehousePalletRequestsURL(data.warehouse.id)} className="btn btn-primary">
                View Pallet Requests
              </LinkButton>
              {data.warehouse.storageTreasuresLink && (
                <LinkButton href={data.warehouse.storageTreasuresLink} className="btn btn-primary" target="_blank">
                  View Storage Treasures Facility
                </LinkButton>
              )}
            </LinkButtonGroup>
          </div>
        )}
        {error && <div className="alert alert-danger">{error}</div>}
        {loadingQuery || !warehouse ? null : (
          <form
            onSubmit={async (event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <div className="panel-body">
              <WarehouseForm
                warehouse={warehouse}
                editAccess={editAccess && editingOtherDetails}
                onChange={setWarehouse}
              />
            </div>
            {editAccess && editingOtherDetails && (
              <div className="panel-footer text-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={loadingMutation || isEqual(savedWarehouse, warehouse)}
                  onClick={onSave}
                >
                  Save
                </button>
              </div>
            )}
          </form>
        )}
      </div>
      <HistoriesPanel type={History__Subject.Warehouse} id={id} />
    </>
  );
};
